<template>
  <v-container fluid class="px-lg-5">
    <div class="col-12">
      <goals-table :tableName="goalTables.USER_GOALS" :goals="goals" :loading="loading"
        @updateOptions="updateOptions"></goals-table>
    </div>
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" fab bottom right fixed class="mr-5" v-on="on"
            v-bind="attrs" @click="newGoal">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('layout.accountNavMenu.newGoal') }}</span>
      </v-tooltip>
    </v-fab-transition>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import goalsTable from 'components/goals/Table.vue';
import {goalTables} from 'constants';

export default {
  name: 'UserGoals',
  components: {goalsTable},
  data() {
    return {
      loading: true,
      goalTables
    };
  },
  computed: mapGetters(['goals', 'currentUser']),
  methods: {
    ...mapActions(['getGoals']),
    updateOptions(options) {
      this.loading = true;
      this.getGoals({
        ...options,
        filterBy: {
          assigneeId: this.$route.params.uuid === 'me' ? this.currentUser.id : this.$route.params.uuid
        }
      }).then(() => {
        this.loading = false;
      });
    },
    newGoal() {
      this.$router.push({name: 'NewGoal', params: {uuid: this.$route.params.uuid}});
    }
  }
};
</script>
